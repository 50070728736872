import {
  EXTRA_PROPERTY_CONFIGURATION_BASE_FRAGMENT,
} from '@/graphql/_Fragments/ExtraPropertyConfiguration/Base';

export const EXTRA_PROPERTY_SECTION_WITHOUT_NMMA_FRAGMENT = `
  fragment extraPropertySectionWithoutNmmaFragment on ExtraPropertySection {
    uid
    schemaCode
    name
    displaySequence
    configsInSection(orderBy: displaySequence_asc"%filter%") {
      ...extraPropertyConfigurationBaseFragment
    }
  }
  ${EXTRA_PROPERTY_CONFIGURATION_BASE_FRAGMENT}
`;
